.App {
  text-align: center;
}

.shop_btn { 
  font-family: 'Montserrat';
  font-size: 18px;
  background: none;
  border: none;
  border-bottom: 1px solid #496523;
  padding: 10px;
  text-align: center;
  color: #5d5340
}


.shop_btn:hover {
  background-color: #49652311;
}